import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth/auth-guards/auth.guard';
const routes: Routes = [
  {
    path: 'modules',
    loadChildren: () => import('./modules/modules.module')
      .then(m => m.ModulesModule),
      canActivate: [AuthGuard],
  },
  {
    path: 'auththenticate',
    loadChildren: () =>import('././auth/auth.module')
      .then(m => m.AuthenticateModule),
  },
  { path: '', redirectTo: 'auththenticate', pathMatch: 'full' },
  { path: '**', redirectTo: 'auththenticate' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
