import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map, mapTo, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import {
  ITokenInfo,
  IUserInfo,
} from "../../auth/data-access/models/user-token.model";
import { UrlConstant } from "../../constants/url.constant";
import { IResponseData } from "../data-access";
import { SecurityUtil } from "../utils/security";
import { AuthStore } from "../utils/store/auth.store";
import { HandlerService } from "./handler-error.service";
import { CookieService } from "ngx-cookie-service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({ providedIn: "root" })
export class AuthService {
  private apiUrl: string = UrlConstant.BASE_URL;
  private jwtToken = "jwt-token";
  private identityKey = "keyLogin";
  private helper = new JwtHelperService();

  private http: HttpClient;
  private cookieService: CookieService;
  private authStore: AuthStore;
  private router: Router;
  private handlerError: HandlerService;

  constructor(private injector: Injector) {
    this.cookieService = injector.get(CookieService);
    this.authStore = injector.get(AuthStore);
    this.router = injector.get(Router);
    this.handlerError = injector.get(HandlerService);
  }

  doLogout(model: any) {
    return this.http
      .post(`${this.apiUrl + UrlConstant.API.ACL_ACCOUNT}/Logout`, model)
      .subscribe(
        () => {
          // if result is oke: token is revoke -> clear access token and refresh token in browser
          this.doBackLogin();
        },
        () => {
          this.doBackLogin();
        }
      );
  }

  doBackLogin() {
    localStorage.removeItem(this.jwtToken);
    // redirect login page
    this.router.navigate([UrlConstant.ROUTE.LOGIN]);
  }

  doRefreshToken(requestRefreshToken: any): Observable<ITokenInfo> {
    const url = `${this.apiUrl + UrlConstant.API.ACL_ACCOUNT}/Refresh-Token`;
    return this.http
      .post<IResponseData<ITokenInfo>>(url, requestRefreshToken)
      .pipe(
        map((res: any) => res.result),
        catchError(this.handlerError.handleError)
      );
  }

  doLogin(requestLogin: any) {
    const header = new HttpHeaders({ Post: "true" });
    const url = `${this.apiUrl + UrlConstant.API.ACL_ACCOUNT}/Login`;
    // this.loader.setLoading(true, url);
    return this.http
      .post(url, requestLogin, {
        headers: header,
      })
      .pipe(
        map((res: any) => {
          // set user token
          if (res.result) {
            this.setUserToken(res.result);
            // update
            this.authStore.setCurrentUser(this.getUserInfo());
            return true;
          }
          return false;
        })
      );
  }

  setUserToken(userToken: ITokenInfo) {
    localStorage.setItem(this.jwtToken, JSON.stringify(userToken));
  }

  getUserInfo() {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return;
    }
    return this.helper.decodeToken(accessToken);
  }

  getAccessToken() {
    const userToken = JSON.parse(
      localStorage.getItem(this.jwtToken)
    ) as ITokenInfo;
    if (!userToken) {
      return;
    }

    return userToken.accessToken;
  }

  getExpiredTime() {
    const userToken = JSON.parse(
      localStorage.getItem(this.jwtToken)
    ) as ITokenInfo;
    if (!userToken) {
      return;
    }

    return userToken.expiresIn;
  }

  isAuthorized() {
    return this.getAccessToken();
  }

  hasPermissionAccess() {
    return true;
  }

  isExcuteRefreshToken() {
    let expiredTime = this.getExpiredTime();
    let refreshTime = this.getTimeSkipUtilForRefreshToken();
    // Nếu token lớn hơn 5 phút thì refresh token
    return expiredTime - refreshTime > 5 * 60;
  }

  tokenExpired() {
    const expiredTime = this.getExpiredTime();
    if (!expiredTime) {
      return false;
    }

    const expiryRemaining =
      expiredTime - Math.floor(new Date().getTime() / 1000);
    // delay 5s chờ refresh token khi chuyển page
    return expiryRemaining > -5;
  }

  setCookieKeyLogin(value: string) {
    this.cookieService.set(this.identityKey, value);
  }

  getCookieKeyLogin() {
    const key = this.cookieService.get(this.identityKey);
    if (key) {
      return key;
    }

    const newKey = SecurityUtil.generateGuid();
    this.setCookieKeyLogin(newKey);
    return newKey;
  }

  clearAll() {
    localStorage.clear();
  }

  private getTimeSkipUtilForRefreshToken() {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return;
    }

    const userInfo = this.helper.decodeToken(accessToken) as IUserInfo;
    if (!userInfo) {
      return;
    }

    let expriedTime = userInfo.expiresIn;

    return expriedTime - Math.floor(new Date().getTime() / 1000);
  }

  private setRequestLogin(url: string, request: any) {
    return this.http.post(url, request).pipe(
      map((res: any) => {
        // set user token
        if (res.result) {
          this.setUserToken(res.result);

          // update
          this.authStore.setCurrentUser(this.getUserInfo());
          return true;
        }
        return false;
      })
    );
  }

  getRefreshToken() {
    const userToken = JSON.parse(
      localStorage.getItem(this.jwtToken)
    ) as ITokenInfo;
    if (!userToken) {
      return;
    }

    return userToken.refreshToken;
  }
}
