

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UrlConstant } from '../../constants/url.constant';
import { AuthService } from '../../shared/services/auth.service';



@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    constructor(public router: Router, private auth: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.auth.isAuthorized()) {
            this.auth.clearAll()
            this.router.navigate([UrlConstant.ROUTE.LOGIN]);
            return false;
        }
        return true;
    }
}