import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbDialogService, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CustomTranslateService } from '../../../shared/services/custom-translate.service';
import { ModulesComponent } from '../../../modules/modules.component';
import { CurrentUser } from '../../../shared/utils/store/current-user';
import { AlertDialogComponent } from '../../../shared/controls/alert-dialog/alert-dialog.component';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  name = ''
  userMenu: any
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    // {
    //   value: 'dark',
    //   name: 'Dark',
    // },
    {
      value: 'cosmic',
      name: 'Dark',
    },
    // {
    //   value: 'corporate',
    //   name: 'Corporate',
    // },
  ];

  langs = [
    {
      value: 'vi',
      name: 'VI',
    },
    {
      value: 'en',
      name: 'EN',
    },
  ];

  currentLang = this.langs[0].value;

  currentTheme = 'default';

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private translate: CustomTranslateService,
    private modules: ModulesComponent,
    private currentUser: CurrentUser,
    private modal: NbDialogService) {
  }


  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme
    this.currentLang = this.translate.getCurrentLang()
    this.userMenu = [
      // { title: this.translate.get("LB.ACTION.PROFILE") }, 
      // { title: this.translate.get("LB.ACTION.LOGOUT") }
    ]

    this.user = this.currentUser.getCurrentUser()
    this.name = this.user.LastName + ' ' + this.user.FirstName

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  changeLang(lang: string) {
    this.translate.setCurrentLang(lang)
    this.modules.loadMenu()
  }

  logout() {
    this.modal.open(AlertDialogComponent, {
      context: {
        title: this.translate.get('MES.ACTION.CONFIRM'),
        message: this.translate.get('MES.ACTION.CONFIRM.LOGOUT'),
      },
    }).onClose
      .subscribe(res => {
        if (res) {
          this.currentUser.logout()
        }
      });
  }
}
