import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { UrlConstant } from '../../../constants/url.constant';
import { LocalService } from '../../../shared/services/local.service';

export interface ICurrentUser {
    CustomerCode: string
    CustomerID: number
    Emterprise: string
    FirstName: string
    LastName: string
    IsPerson: boolean
}

@Injectable({ providedIn: 'root' })

export class CurrentUser {
    constructor(injector: Injector) {
        this.localStore = injector.get(LocalService);
        this.router = injector.get(Router);
        this.userSessionKey = "userSessionKey";
    }
    readonly userSessionKey: string;
    protected router: Router;
    protected localStore: LocalService;
    readonly setCurrentUser = (user: string) => this.localStore.saveData(this.userSessionKey, user);
    readonly getCurrentUser = () => {
        try {
            let user = this.localStore.getData(this.userSessionKey)
            if(user === "" || user === null || user === undefined)
                return null
           return JSON.parse(user)
        }
        catch {
            return null
        }
    }

    readonly logout = () => {
        this.localStore.clearData()
        window.open('/','_self')
    }
}
