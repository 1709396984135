import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take ,tap,finalize, map} from 'rxjs/operators';
import { ITokenInfo } from "../../../auth/data-access/models/user-token.model";
import { JwtUtil } from "../store/jwt";
import { environment } from "../../../../environments/environment";
import { SafeAny } from "../types";
const TOKEN_HEADER_KEY = 'Authorization';
@Injectable()
export class AuthCoopInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<SafeAny> = new BehaviorSubject<SafeAny>(null);
    http: HttpClient ;
    constructor(injector: Injector) {      
        this.http = injector.get(HttpClient);
      }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
        
        return next.handle(this.addAuthenticationToken(req)).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              return this.handle401Error(req, next);
            }
            return throwError(error);
          }));
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // which means the new token is ready and we can retry the request again
            return this.refreshTokenSubject.pipe(
                filter((result) => result !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthenticationToken(request)))
            );
        } else {
            this.refreshTokenInProgress = true;

            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);

            return this.refreshAccessToken().pipe(
                switchMap((success: boolean) => {
                    this.refreshTokenSubject.next(success);
                    return next.handle(this.addAuthenticationToken(request));
                }),
                // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                // for the next time the token needs to be refreshed
                finalize(() => {
                    this.refreshTokenInProgress = false;
                })
            );
        }
    }

    private refreshAccessToken(): Observable<SafeAny> {
         //nuild api login url
        const apiBaseUrl = environment.apiServer;
        const apiRelativeUrl = "token";
        const url = `${apiBaseUrl}/${apiRelativeUrl}`;

        //build request body
        const body = `grant_type=password&password=DIP20231@4&username=userCoopMartPDev`;
       
        return  this.http.post(url, body).pipe(
            map((res: SafeAny) => {
                // set user token
                const tokenInfo : ITokenInfo =  {
                    accessToken: res.access_token,
                    refreshToken: res.access_token,
                    expiresIn: res.expires_in
                }    
                JwtUtil.setUserToken(tokenInfo);     
        
                return tokenInfo.accessToken;
            }));
    }

    private addAuthenticationToken(request: HttpRequest<SafeAny>): HttpRequest<SafeAny> {
        // Get an access token
        const token = JwtUtil.getAccessToken();
        if (!token) {
            return request;
        }
        return request.clone({
            headers: request.headers
                .set(TOKEN_HEADER_KEY, 'Bearer ' + token)
                .set('Access-Control-Max-Age', '86400')
                .set('Content-Type', 'application/json'),
        });
    }
}