export const data = {
  en: {
    QLTK: 'Account Management',
    DSTK: 'Account List',
    TMTK: 'Update account',
    DMK: 'Change password',
    KHCN: 'Individual Customers',
    QLTTDK: 'Manage registration',
    KHDN: 'Corporate customers',
    TCLN: 'Personal quote',
    TLDN: 'Business quote'
  },
  vi: {
    QLTK: 'Quản lý tài khoản',
    DMK: 'Đổi mật khẩu',
    DSTK: 'Danh sách tài khoản',
    TMTK: 'Cập nhật tài khoản',
    KHCN: 'Khách hàng cá nhân',
    QLTTDK: 'Quản lý đăng ký thông tin',
    KHDN: 'Khách hàng doanh nghiệp',
    TCLN: 'Trích lục cá nhân',
    TLDN: 'Trích lục doanh nghiệp'
  }
}
