import { Component, Injector, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { LangEnum } from '../constants/enum.constant';
import { CustomTranslateService } from '../shared/services/custom-translate.service';
import { CurrentUser } from '../shared/utils/store/current-user';
import { data } from './data-access/menu-list';
@Component({
  selector: 'ngx-modules',
  styleUrls: ['modules.component.scss'],
  template: `
    <ngx-one-column-layout [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerStatus="info">
      <nb-menu [items]="menu"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-one-column-layout>
  `,
})
export class ModulesComponent implements OnInit {
  menu: NbMenuItem[]
  dataMenu = data
  isLoading: Boolean = false
  constructor(
    private translate: CustomTranslateService,
    private currentUser: CurrentUser) {

  }

  ngOnInit(): void {
    this.isLoading = true
    this.loadMenu()
    this.isLoading = false
  }

  loadMenu() {
    const user = this.currentUser.getCurrentUser()
    if(user) {
      this.menu = this.getMenu().filter(m=> m.isPerson == user.IsPerson || m.isPerson == null)
    }
  }

  getMenu() {
    const currentLang = this.translate.getCurrentLang()
    const data = currentLang != LangEnum.VI ? this.dataMenu.en : this.dataMenu.vi
    return [
      {
        title: data.QLTK,
        icon: 'lock-outline',
        home: true,
        isPerson: null,
        children: [
       
          {
            title: data.TMTK,
            icon: 'edit-2-outline',
            link: '/modules/module-dang-ky/form-tai-khoan',
          },   
          {
            title: data.DMK,
            icon: 'color-picker-outline',
            link: '/modules/module-dang-ky/change-password',
          }
        ]
      },
      {
        title: data.KHCN,
        icon: 'people-outline',
        home: true,
        isPerson: true,
        children: [
          {
            title: data.QLTTDK,
            icon: 'monitor-outline',
            link: '/modules/module-canhans/ca-nhan',
          },
          {
            title: data.TCLN,
            icon: 'monitor-outline',
            link: '/modules/module-canhans/trich-luc',
          },
        ]
      },
      {
        title: data.KHDN,
        icon: 'npm-outline',
        home: true,
        isPerson: false,
        children: [
          {
            title: data.QLTTDK,
            icon: 'monitor-outline',
            link: '/modules/module-doanhnghieps/doanh-nghiep',
          },
          {
            title: data.TLDN,
            icon: 'monitor-outline',
            link: '/modules/module-doanhnghieps/trich-luc',
          },
        ]
      },
    ];
  }

}
