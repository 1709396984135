import { environment } from "../../environments/environment";

export const UrlConstant = {   
    BASE_URL: environment.apiServer,
    ROUTE: {
        LOGIN: '/auththenticate/login',
        DASHBOARD: '',
        FORBIDEN: ''
    },
       
    CKEDITOR: {
        STORE_FILE:'',
        UPLOAD_FILE:''
    },

    API: {
       
        ACL_ACCOUNT: "/api/LoginCustomer/logincustomer",
    

    },

};
