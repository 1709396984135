import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangEnum } from '../../constants/enum.constant';
import { SafeAny } from '../utils/types';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root',
})
export class CustomTranslateService {
  translateResources: SafeAny;
  langs = [
    {
      symbol: LangEnum.VI,
      name: 'Tiếng Việt',
      path: './assets/images/Flag_vietnam.png',
    },
    {
      symbol: LangEnum.EN,
      name: 'Tiếng Anh',
      path: './assets/images/Flag_kingdom.png',
    },
  ];

  keyLanglocalStorage = 'lang'

  constructor(
    private translate: TranslateService,
    private localStore: LocalService) {
    this.translate.use(this.getCurrentLang());
    // this.load();
  }

  load() {
    const browserLang = this.getCurrentLang();
    this.translate.use(browserLang.match(/en|vi/) ? browserLang : LangEnum.VI);
  }

  get(key: string): string {
    const currentLang = this.getCurrentLang();
    const returnValue = this.translate.translations[currentLang][key]; // get converted string from current language
    if (returnValue === undefined) {
      return this.translate.translations.en_merch[key]; // if value is getting undefined then return default language string, en_merch is default english language file here
    } else {
      return returnValue;
    }
  }

  setCurrentLang(lang: string) {
    if (!lang || !lang.match(/en|vi/)) {
      lang = LangEnum.VI
    }
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.localStore.saveData(this.keyLanglocalStorage, lang)
  }

  getCurrentLang() {
    let currentLang = this.localStore.getData(this.keyLanglocalStorage)
    if (currentLang == null || currentLang == undefined || currentLang == '') {
      currentLang = LangEnum.VI;
    }
    return currentLang as LangEnum;
  }

  languageName() {
    const currentLang = this.getCurrentLang();
    const findNameLang = this.langs.find(m => m.symbol === currentLang);
    if (!findNameLang) return null;
    return findNameLang.name;
  }

  languageImage() {
    const currentLang = this.getCurrentLang();
    const findPathImage = this.langs.find(m => m.symbol === currentLang);
    if (!findPathImage) return null;
    return findPathImage.path;
  }
}
